/**
 * This class add support to js modules
 */
export default class Modules {
  /**
   * Construct method
   *
   * @param {Class} ModuleClass
   */
  constructor(ModuleClass) {
    this.ModuleClass = ModuleClass;

    this.navigation();
  }

  /**
   * Run modules if it's first load or by barba navigation
   */
  navigation() {
    const self = this;
    window.addEventListener("DOMContentLoaded", () => {
      self.initModules();
    });

    const pageNavigation = document
      .querySelector("body")
      .getAttribute("page-loaded");
    if (pageNavigation) {
      this.initModules();
    }
  }

  /**
   * Init js per module
   */
  initModules() {
    const self = this;
    const modules = document.querySelectorAll(`.${this.ModuleClass.getName()}`);

    modules.forEach((module) => {
      setTimeout(() => {
        new self.ModuleClass(module);
      }, 100);
    });
  }
}
